export default function() {

  const showDuration = 3000;

  return {

    _status: null,
    message: null,
    showMessage: false,

    set status(e) {
      this._status = e.detail.status;
      this.message = e.detail.message;
      this.showMessage = this.message && true;
      setTimeout(() => this.showMessage = false, showDuration);
    },

    get status() {
      return this._status;
    },

    init() {
      window.addEventListener('cart-status', event => this.status = event);
    }
  };

}
