/**
 * Product component
 *
 * Usage:
 *
 *   <div x-data="product('{{ product.shopifyId }}', '{{ product.url }}')">
 *     ...
 *     <button x-on:click="addLineItem('{{ product.getDefaultVariant().id }}')">Add to Cart</button>
 *   </div>
 *
 * @param id
 * @param url
 * @returns {{init(): Promise<void>, product: null, addLineItem(*, number=): Promise<void>, id, url: string}}
 */
export default function(id, url = '') {
  let base = 'gid://shopify/ProductVariant/';

  return {
    /**
     * ID of product
     */
    id: id,

    /**
     * Memoized copy of product
     */
    product: null,

    /**
     * Url to product page in Craft
     */
    url: url,

    /**
     * Internal flag indicating that an update is underway
     */
    updating: false,

    /**
     * Component init, fetches product details from API
     *
     * @returns {Promise<void>}
     */
    async init() {
      /* Demonstrate how to fetch product once the store is ready...
      document.addEventListener('alpine:initialized', async () => {
        try {
          let p = await Shopify.getProduct('gid://shopify/Product/' + this.id);
          this.product = JSON.parse(JSON.stringify(p));
          } catch(e) {}
      });
       */
    },

    /**
     * Add variant to cart
     * Supports passing id or admin_graphql_api_id
     *
     * @param variantId
     * @param qty
     * @returns {Promise<void>}
     */
    async addLineItem(variantId, qty = 1) {
      this.updating = true;
      let gid = variantId.startsWith(base) ? variantId : base + variantId;
      await Alpine.store('cart').addLineItem(
        gid,
        qty,
        [
          {
            key: '_productUrl',
            value : this.url,
          }
        ]
      );
      console.log('Added ' + variantId);
      this.updating = false;
    }
  };
}
