import Alpine from 'alpinejs';
import Client from 'shopify-buy';

const messages = {
  addLineItems: {
    success: 'Added to cart',
    error: 'Sorry, there was an error adding this to your cart',
    logSuccess: 'Line items added'
  },
  removeLineItems: {
    success: 'Removed from cart',
    error: 'Sorry, there was an error removing this from your cart',
    logSuccess: 'Line items removed'
  },
  updateLineItems: {
    success: 'Quantity updated',
    error: 'Sorry, there was an error updating the quantity',
    logSuccess: 'Line items updated'
  }
};

export default function(domain, token) {

  /**
   * Local instance of Client
   * @type {Client}
   */
  const client = Client.buildClient({
    domain: domain,
    storefrontAccessToken: token
  });

  /**
   * Helper object used to define our empty checkout object
   * @type {{amount: string, currencyCode: string}}
   */
  const moneyPlaceholder = {
    amount: "0.0",
    currencyCode: "GBP"
  };

  /**
   * An empty object that we use so that
   * our components always have something to work with.
   * https://shopify.dev/docs/api/storefront/2023-01/objects/checkout
   *
   * @type {{note: null, totalPrice: {amount: string, currencyCode: string}, orderStatusUrl: null, lineItems: *[], totalTax: {amount: string, currencyCode: string}, createdAt: string, ready: boolean, id: null, subtotalPrice: {amount: string, currencyCode: string}, email: null, order: null, updatedAt: string, taxesIncluded: boolean, completedAt: null, taxExempt: boolean, discountApplications: *[], paymentDue: {}, appliedGiftCards: *[], requiresShipping: boolean, webUrl: null, lineItemsSubtotalPrice: {amount: string, currencyCode: string}, shippingAddress: null, currencyCode: string, shippingLine: null, customAttributes: *[]}}
   */
  const emptyCheckout = {
    appliedGiftCards: [],
    completedAt: null,
    createdAt: "",
    currencyCode: "GBP",
    customAttributes: [],
    discountApplications: [],
    email: null,
    id: null,
    lineItems: [],
    lineItemsSubtotalPrice: {...moneyPlaceholder},
    note: null,
    order: null,
    orderStatusUrl: null,
    paymentDue: {},
    ready: true,
    requiresShipping: true,
    shippingAddress: null,
    shippingLine: null,
    subtotalPrice: {...moneyPlaceholder},
    taxExempt: false,
    taxesIncluded: true,
    totalPrice: {...moneyPlaceholder},
    totalTax: {...moneyPlaceholder},
    updatedAt: "",
    webUrl: null
  };

  return {

    /**
     * Memoized & "deconstructed" copy of the Checkout object
     * @private
     */
    _checkout: {...emptyCheckout},

    /**
     * Internal record of checkout ID, saved in localStorage
     * @private
     */
    _checkoutId: Alpine.$persist(null).as('shopifyCheckoutId'),

    /**
     * Internal flag for debugging
     * @private
     */
    _debug: Alpine.$persist(false).as('shopifyDebug'),

    /**
     * Array of errors from this component
     */
    errors: [],

    /**
     * Total number of items in cart
     */
    totalQty: 0,

    /**
     * For static translations of user messages (for later...)
     */
    translations: {},

    /**
     * Called automatically upon component initialization
     */
    init() {
      this.log('Component init');

      // Reference self on a global variable, so for example we can write:
      // `Shopify.addLineItem()` instead of `Alpine.store('cart').addLineItem()`
      window.Shopify = this;

      // Wait until Alpine.$persist() has done its thing
      // This could also be done with nextTick(), setTimeout(), queueMicrotask(), etc
      document.addEventListener('alpine:initialized',async () => {
        if(this._checkoutId) {
          try {
            this.log('Fetching checkout');
            let c = await client.checkout.fetch(this._checkoutId);

            if(c.completedAt) {
              this.log('Cart is complete');
              this.reset();
              return;
            }

            this.log('Memoizing fetched checkout', c);
            this.checkout = c;
          } catch(e) {
            this._checkoutId = null;
            this.addError('Error encountered while fetching checkout', e);
          }
        }
      });
    },

    /** =================================================
     * Getters & Setters
     * ==================================================*/

    /**
     * this.checkout getter
     * @returns {{note: null, totalPrice: {amount: string, currencyCode: string}, orderStatusUrl: null, lineItems: *[], totalTax: {amount: string, currencyCode: string}, createdAt: string, ready: boolean, id: null, subtotalPrice: {amount: string, currencyCode: string}, email: null, order: null, updatedAt: string, taxesIncluded: boolean, completedAt: null, taxExempt: boolean, discountApplications: *[], paymentDue: {}, appliedGiftCards: *[], requiresShipping: boolean, webUrl: null, lineItemsSubtotalPrice: {amount: string, currencyCode: string}, shippingAddress: null, currencyCode: string, shippingLine: null, customAttributes: *[]}}
     */
    get checkout() {
      return this._checkout;
    },

    /**
     * this.checkout setter
     * Obfuscates our "deconstruct" method and re-calculates other dependent props
     * @param c
     */
    set checkout(c) {
      this._checkout = JSON.parse(JSON.stringify(c));
      this.totalQty = c.lineItems.reduce((a, b) => a + (b.quantity || 0), 0);
    },

    /** =================================================
     * Component Methods
     * ==================================================*/

    /**
     * Store component error
     * @param msg
     * @param exception
     */
    addError(msg, exception = null) {
      this.errors.push(msg);
      this.logError(msg);
      if(exception) this.logError(exception);
    },

    /**
     * Enable/disable logging of notices, errors & other debug statements to the console.
     *
     * Usage (from the browser CLI):
     *
     * To enable: Alpine.store('cart').debug(1)
     * To disable: Alpine.store('cart').debug(0)
     * @param v
     */
    debug(v) {
      this._debug = !! v;
    },

    /**
     * Log message to console if debug mode is on
     * @param message
     * @param obj
     */
    log(message, obj) {
      if(this._debug) {
        console.log(message);
        if(obj) console.log(obj);
      }
    },

    /**
     * Log error message to console if debug mode is on
     * @param message
     */
    logError(message) {
      if(this._debug) {
        console.log(`%c${message}`, `color: red; font-weight: bold`);
      }
    },

    /**
     * Log success message to console if debug mode is on
     * @param message
     */
    logSuccess(message) {
      if(this._debug) {
        console.log(`%c${message}`, `color: green; font-weight: bold`);
      }
    },

    /**
     * Translate message if available (one for later...)
     * @param message
     * @returns {*}
     */
    translate(message) {
      if(!this.translations[message]) return message;
      return this.translations[message] || message;
    },

    /**
     * Do something based on error/success status of checkout object
     * TODO: check for sources of errors other than checkout.userErrors
     * @param checkout
     * @param messages
     */
    handleResponse(checkout, messages) {
      if(checkout.userErrors.length) {
        this.handleErrors(checkout.userErrors, messages.error);
      }
      else {
        this.logSuccess(messages.logSuccess || messages.success);
        this.dispatchCartEvent('success', messages.success);
      }
    },

    /**
     * Log/store errors and dispatch event
     * @param errors
     */
    handleErrors(errors, message) {
      errors.forEach(e => this.addError(e.message));
      this.dispatchCartEvent('error', message);
    },

    /**
     * Helper function to dispatch all events to the window
     * @param type
     * @param detail
     */
    dispatchEvent(type, detail = null) {
      this.log(`Dispatching event (${type})`);
      if(detail) this.log(detail);
      const event = new CustomEvent(type, { detail });
      window.dispatchEvent(event);
    },

    /**
     * Dispatch cart specific events
     * @param status
     * @param message
     */
    dispatchCartEvent(status, message) {
      this.dispatchEvent(
        'cart-status',
        { status: status, message: message && this.translate(message) }
      );
    },

    /**
     * Return a formatted currency string, given a "Money" object
     * See also: https://stackoverflow.com/questions/49724537/intl-numberformat-either-0-or-two-fraction-digits/49724586#49724586
     *
     * Usage:
     * To automatically drop decimal places if not needed:
     *   <p x-text="Shopify.moneyFormat(Shopify.checkout.totalPrice)"></p>
     *
     * To always output decimal places, even if `.00`:
     *   <p x-text="Shopify.moneyFormat(Shopify.checkout.totalPrice, false)"></p>
     *
     * @param money
     * @param allowPrecision
     * @returns {string}
     */
    moneyFormat(money, allowPrecision = true) {
      // cast to number
      let amount = Number(money.amount);

      // round to zero decimal places?
      if(allowPrecision && amount % 1 == 0) {
        return (new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: money.currencyCode,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })).format(amount);
      }

      // format with decimal places
      return (new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: money.currencyCode,
        minimumFractionDigits: 2,
      })).format(amount);
    },

    /**
     * Reset component
     */
    reset() {
      this.log('Resetting component');
      this._checkoutId = null;
      this._checkout = {...emptyCheckout};
      this.errors = [];
      this.totalQty = 0;
      this.message = '';
      this.log('Component reset');
    },

    /** =================================================
     * ASYNC Component Methods
     * ==================================================*/

    /**
     * Add single line item to cart, internally uses this.addLineItems()
     *
     * Usage:
     *
     *   Shopify.addLineItem('1234567890', 1, [
     *     {
     *       key: '_productUrl',
     *       value : 'https://path-to-product-page',
     *     }
     *   ]);
     *
     * @param id
     * @param qty
     * @param attrs
     * @returns {Promise<void>}
     */
    async addLineItem(id, qty = 1, attrs = []) {
      let lineItemsToAdd = [
        {
          variantId: id,
          quantity: qty,
          customAttributes: attrs
        }
      ];
      await this.addLineItems(lineItemsToAdd);
    },

    /**
     * Add multiple variant line item to cart
     *
     * Usage:
     *
     *   Shopify.addLineItems([
     *     {
     *       variantId: '1234567890',
     *       quantity: 1
     *       customAttributes: [{
     *         key: '_productUrl',
     *         value : 'https://path-to-product-page',
     *       }]
     *     },
     *     {
     *       variantId: '1234567890',
     *       quantity: 1
     *       customAttributes: [{
     *         key: '_productUrl',
     *         value : 'https://path-to-product-page',
     *       }]
     *     },
     *   ]);
     *
     * @param items
     * @returns {Promise<void>}
     */
    async addLineItems(items = []) {
      this.log('Adding line items');
      this.dispatchEvent('cart-updating');
      try {
        let cid = await this.id();
        let c = await client.checkout.addLineItems(cid, items);
        this.checkout = c;
        this.handleResponse(this.checkout, messages.addLineItems);
      } catch(e) {
        this.addError('Error encountered while adding line items', e);
        this.dispatchCartEvent('error', this.translate(messages.addLineItems.error));
      }
      this.dispatchEvent('cart-updated');
    },

    /**
     * Returns existing ID or creates a new one
     * @returns {Promise<*>}
     */
    async id() {
      if( ! this._checkoutId) {
        try {
          this.log('Creating checkout');
          let c = await client.checkout.create();
          this.log('Memoizing created checkout', c);
          this.checkout = c;
          this._checkoutId = c.id;
        } catch(e) {
          this.addError('Error encountered while creating checkout', e);
        }
      }
      this.log('Returning memoized checkout id');
      return this._checkoutId;
    },

    /**
     * Return a product for a given ID
     * https://shopify.dev/docs/api/storefront/2023-01/objects/Product
     *
     * Usage:
     *
     *   try {
     *     let response = await Shopify.getProduct('gid://shopify/Product/12345');
     *     let product = JSON.parse(JSON.stringify(response));
     *   } catch(e) {}
     *
     * @param id
     * @returns {Promise<*>}
     */
    async getProduct(id) {
      this.log('Getting product', id);
      try {
        let p = await client.product.fetch(id);
        this.log('Product fetched', p);
        return p;
      } catch(e) {
        this.addError('Error encountered while fetching product', e);
      }
    },

    /**
     * Remove line item from cart
     *
     * Usage:
     *
     *   Shopify.removeLineItem(id);
     *
     * @param id
     * @returns {Promise<void>}
     */
    async removeLineItem(id) {
      await this.removeLineItems([id]);
    },

    /**
     * Remove line items from cart
     *
     * Usage:
     *
     *   Shopify.removeLineItems([id1, id2]);
     *
     * @param ids
     * @returns {Promise<void>}
     */
    async removeLineItems(ids) {
      this.log('Removing line items');
      this.dispatchEvent('cart-updating');
      try {
        let cid = await this.id();
        let c = await client.checkout.removeLineItems(cid, ids);
        this.checkout = c;
        this.handleResponse(this.checkout, messages.removeLineItems);
      } catch(e) {
        this.addError('Error encountered while removing line items', e);
        this.dispatchCartEvent('error', this.translate(messages.removeLineItems.error));
      }
      this.dispatchEvent('cart-updated');
    },

    /**
     * Update line item in cart
     *
     * Usage:
     *
     *   Shopify.updateLineItem({
     *     id: 'gid://12345',
     *     quantity: 2
     *   });
     *
     * @param ids
     * @returns {Promise<void>}
     */
    async updateLineItem(item) {
      await this.updateLineItems([item]);
    },

    /**
     * Update line items in cart
     *
     * Usage:
     *
     *   Shopify.updateLineItem([
     *     {
     *       id: 'gid://12345',
     *       quantity: 2
     *     },
     *     {
     *       id: 'gid://67890',
     *       quantity: 3
     *     },
     *   );
     *
     * @param ids
     * @returns {Promise<void>}
     */
    async updateLineItems(items) {
      this.log('Updating line items', items);
      this.dispatchEvent('cart-updating');
      try {
        let cid = await this.id();
        let c = await client.checkout.updateLineItems(cid, items);
        this.checkout = c;
        this.handleResponse(this.checkout, messages.updateLineItems);
      } catch(e) {
        this.addError('Error encountered while updating line items', e);
        this.dispatchCartEvent('error', this.translate(messages.updateLineItems.error));
      }
      this.dispatchEvent('cart-updated');
    }
  };
}
