export function app() {
  return {
    form: { title: '' },
    returnLayers: [],
    search: false,
    miniCart: false,

    init() {
      /*
      window.addEventListener('up:layer:opened', (event) => {
        console.log(this.returnLayers);
        console.log('up:layer:opened', event.layer.stack);
        if(this.returnLayers.length > 1) {
          //console.log(this.returnLayers[this.returnLayers.length - 2]);
          let returnLayer = this.returnLayers[this.returnLayers.length - 2];
          event.layer.contentElement.appendChild(up.element.createFromHTML(`<a href='${returnLayer.location}' up-mode='modal' up-layer='swap' up-size='grow' up-target='${returnLayer.target}'>x</a>`));
        }
      });

      window.addEventListener('up:layer:dismissed', (event) => {
        console.log(event.layer.stack, this.returnLayers);
      });
       */
    }
  };
}
